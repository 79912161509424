var sidebarmenu = [{
    'route_name': 'welcome',
    'route_path': '/welcome',
    'sub_menu': [],
    'caption': 'Anasayfa'
  },
  {
    'route_name': 'bsapage',
    'route_path': '/bsapage',
    'sub_menu': [],
    'caption': 'Vücut Yüzey Alanı'
  },
  {
    'route_name': 'gfrpage',
    'route_path': '/gfrpage',
    'sub_menu': [],
    'caption': 'Gfr'
  },
  {
    'route_name': 'treatmentschemaselection',
    'route_path': '/treatmentschemaselection',
    'sub_menu': [],
    'caption': 'Şema Seçimi'
  },
  {
    'route_name': 'treatmentschemadrugs',
    'route_path': '/treatmentschemadrugs',
    'sub_menu': [],
    'caption': 'Şema İlaçları'
  },
  {
    'route_name': 'diagnosis',
    'route_path': '/diagnosis',
    'sub_menu': [],
    'caption': 'Tanı Seçimi'
  },
  {
    'route_name': 'newtreatment',
    'route_path': '/newtreatment',
    'sub_menu': [],
    'caption': 'Yeni Tedavi'
  },
  {
    'route_name': 'treatmentlist',
    'route_path': '/treatmentlist',
    'sub_menu': [],
    'caption': 'Hasta Tedavi Listesi'
  },
  {
    'route_name': 'treatmentdaymanagement',
    'route_path': '/treatmentdaymanagement',
    'sub_menu': [],
    'caption': 'Tedavi Gün Yönetimi'
  },
  {
    'route_name': 'drugapproval',
    'route_path': '/drugapproval',
    'sub_menu': [],
    'caption': 'İlaç Onay'
  },
  {
    'route_name': 'treatmentdatechangepage',
    'route_path': '/treatmentdatechangepage',
    'sub_menu': [],
    'caption': 'Tarih Öteleme'
  },
  {
    'route_name': 'treatmentdrugarrangepage',
    'route_path': '/treatmentdrugarrangepage',
    'sub_menu': [],
    'caption': 'İlaç Uygulama Düzenleme'
  },
  {
    'route_name': 'treatmentcycleincreasepage',
    'route_path': '/treatmentcycleincreasepage',
    'sub_menu': [],
    'caption': 'Siklus Arttırma'
  },
  {
    'route_name': 'treatmentdrugcycledayarrangement',
    'route_path': '/treatmentdrugcycledayarrangement',
    'sub_menu': [],
    'caption': 'İlaç Siklus & Gün Düzenleme'
  },
  {
    'route_name': 'unittreatmentlist',
    'route_path': '/unittreatmentlist',
    'sub_menu': [],
    'caption': 'Ünite tedavi listesi'
  },
  {
    'route_name': 'databasetablelist',
    'route_path': '/databasetablelist',
    'sub_menu': [],
    'caption': 'Tablo listesi'
  },
  {
    'route_name': 'databasetablearchitecture',
    'route_path': '/databasetablearchitecture',
    'sub_menu': [],
    'caption': 'Tablo ilişkileri'
  },
  {
    'route_name': 'databasetables',
    'route_path': '/databasetables',
    'sub_menu': [],
    'caption': 'Sql veritabanı'
  },
  {
    'route_name': 'login',
    'route_path': '/login',
    'sub_menu': [],
    'caption': 'Login'
  },
  {
    'route_name': 'logout',
    'route_path': '/logout',
    'sub_menu': [],
    'caption': 'Logout'
  },
  {
    'route_name': 'worklisttotal',
    'route_path': '/worklisttotal',
    'sub_menu': [],
    'caption': 'Tüm iş listesi'
  },
  {
    'route_name': 'worklisttotaltable',
    'route_path': '/worklisttotaltable',
    'sub_menu': [],
    'caption': 'Tüm iş liste tablosu'
  },
  {
    'route_name': 'version',
    'route_path': '/version',
    'sub_menu': [],
    'caption': 'Versiyon'
  },
  {
    'route_name': '',
    'route_path': '',
    'url': 'https://wisdomera.io',
    'sub_menu': [],
    'caption': 'WisdomEra'
  },
];

export default {
  sidebarmenu
  // f_updateItems: function() {}
}
