<template>
  <div class="app">
    <div class="app-body" :style="d_showAplicationHeader ? 'margin-bottom: 0px;' : 'margin-top: 0px;'">
      <div class="navbar_class">
        <b-row style="margin: 0px;">
          <b-col cols="5">
            <b-button title="Anasayfa" @click="f_goToMainPage()" size="lg" style="padding: 0px;" class="pull-left" variant="white"><img src="@/icon/23380.png" width="50px;" />
              <span style="text-shadow: -1px -1px rgb(0, 0, 0);"> Woncology</span>
            </b-button>
          </b-col>
          <b-col cols="2" style="text-align: center; margin-top: 10px;">
            <strong style="cursor: pointer; font-size: 16px;" @click="f_goToMainPage()">
              <img src="@/icon/3485397.png" style="width: 2em;" />
            </strong>
          </b-col>
          <b-col cols="5">
            <b-button v-if="user" title="Çıkış Yap" @click="f_logout()" size="lg" style="padding: 0px;" class="pull-right" variant="white"><img src="@/icon/429539.png" width="50px;" /> {{ f_showUserName() }}
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="sidebar_class general_scroll_class" :style="f_sideBarStyle()">
        <b-row class="menu-item">
          <b-col cols="12" style="color: rgb(0, 132, 184); cursor: pointer;">
            <img src="@/icon/3485397.png" style="width: 20px; margin-left: -5px;" /> <strong>Woncology-Vue v{{ d_version }} </strong>
          </b-col>
        </b-row>
        <template v-for="(menu_item, menu_item_index) in d_sideBarMenuList">
          <b-row class="menu-item" :style="f_calculateMenuItemStyle(menu_item.route_name)">
            <template v-if="menu_item.route_path">
              <b-col cols="12" @click="f_goToThisPage(menu_item.route_path)">
                <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> --><i class="fa fa-angle-right"></i> {{ menu_item.caption }}
              </b-col>
            </template>
            <template v-else-if="menu_item.url">
              <b-col cols="12" @click="f_goToThisUrl(menu_item.url)">
                <i class="fa fa-angle-right"></i> {{ menu_item.caption }} <img src="@/icon/2230573.png" style="width: 30px;" />
              </b-col>
            </template>
            <template v-else>
              <b-col cols="12">
                <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> --><i class="fa fa-angle-right"></i> {{ menu_item.caption }}
              </b-col>
            </template>
          </b-row>
          <template v-if="menu_item.sub_menu && menu_item.sub_menu.length > 0" v-for="(sub_menu_item, sub_menu_item_index) in menu_item.sub_menu">
            <b-row class="sub-menu-item" :style="f_calculateMenuItemStyle(sub_menu_item.route_name)">
              <template v-if="menu_item.route_path">
                <b-col cols="12" style="color: rgb(3, 183, 254); cursor: pointer;" @click="f_goToThisPage(sub_menu_item.route_path)">
                  <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> --><i class="fa fa-angle-double-right" style="margin-left: 5px;"></i> {{ sub_menu_item.caption }}
                </b-col>
              </template>
              <template v-else-if="sub_menu_item.url">
                <b-col cols="12" @click="f_goToThisUrl(sub_menu_item.url)">
                  <i class="fa fa-angle-double-right"></i> {{ sub_menu_item.caption }} <img src="@/icon/2230573.png" style="width: 30px;" />
                </b-col>
              </template>
              <template v-else>
                <b-col cols="12">
                  <!-- <img src="@/icon/2751485.png" style="width: 30px;" /> --><i class="fa fa-angle-double-right"></i> {{ sub_menu_item.caption }}
                </b-col>
              </template>
            </b-row>
          </template>
        </template>
      </div>
      <main class="text-class" style="padding: 10px; margin-left: 325px;">
        <div id="router-div" style="margin-top: 50px;">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <b-modal id="store-loading" v-model="ShowLoadingModal" no-close-on-esc no-close-on-backdrop centered hide-footer hide-header-close body-bg-variant="dark" style="border-radius: 30px!important; z-index: 9999; box-shadow: -1px -1px 1px;">
      <b-row v-if="StoreLoading.data.label" style="vertical-align: middle; text-align: center;">
        <b-col sm="12" lg="1">
          <!-- <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> -->
          <img src="../../public/img/gif/right-left.gif" class="img-rounded img-responsive" style="width: 30px; height: 30px;" />
        </b-col>
        <b-col sm="12" lg="11">
          <div style="padding-top: 5px;">{{ StoreLoading.data.label }}</div>
          <template v-if="d_progressData">
            <b-progress :value="d_progressData.percent" variant="success" show-progress striped :animated="true" class="mb-2"></b-progress>
            <div style="padding-top: 5px;">
              <span v-if="d_progressData.total_process_count"> Toplam İşlem: {{ d_progressData.total_process_count }} </span>
              <span v-if="d_progressData.process_count">İşlem Adedi: {{ d_progressData.process_count }} </span>
            </div>
            <div v-if="d_progressData.message" style="padding-top: 5px;"> Mesaj: {{ d_progressData.message }} </div>
          </template>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="store-modal" v-model="show_store_modal" :title="StoreModal.data.title ? StoreModal.data.title : 'Uyarı'" :centered="StoreModal.data.centered ? StoreModal.data.centered : true" :header-bg-variant="StoreModal.data.header_bg_variant ? StoreModal.data.header_bg_variant : 'info'" :header-text-variant="StoreModal.data.header_text_variant ? StoreModal.data.header_text_variant : 'dark'">
      <div style="white-space: pre-line;" v-if="StoreModal.data.text">{{ StoreModal.data.text }}</div>
      <template slot="modal-footer">
        <b-button v-if="StoreModal.data.type === 'alert'" @click="show_store_modal = false">ok</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="show_store_modal = false">cancel</b-button>
        <b-button v-if="StoreModal.data.type === 'confirm'" @click="ConfirmStoreModal()">İşlemi gerçekleştir</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import auth from '@/auth';
import { version } from '@/config';
import _menu from '@/_menu';
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as WisdomSocket
} from '@/components/socket/index';
import store from '@/store';

export default {
  name: 'DefaultContainer',
  components: {
    Modal,
    WisdomSocket
  },
  computed: {
    ...mapGetters({
      sidebar: 'sidebar',
      device: 'device',
      help: 'help',
      StoreModal: 'modal',
      StoreLoading: 'loading',
    }),
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.value)
    }
  },
  created: function () {
    if (['showonedocument', 'wdmr'].indexOf(this.$route.name) !== -1) {
      this.d_showAplicationHeader = false;
      this.d_showAplicationSidebar = false;
      this.d_showAplicationFooter = false;
    }
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  destroyed () {},
  beforeCreate: function () {},
  mounted: function () {
    if (this.StoreLoading.status) {
      this.ShowLoadingModal = true;
    }
  },
  data () {
    return {
      d_version: version,
      d_sideBarMenuList: _menu.sidebarmenu,
      d_showAplicationFooter: true,
      d_showAplicationSidebar: true,
      d_showAplicationHeader: true,
      d_showSideBar: true,
      d_progressData: '',
      ShowLoadingModal: false,
      show_store_modal: false,
      store: store,
      user: {},
      d_showRightMenu: false,
    }
  },
  methods: {
    f_goToMainPage: function () {
      this.$router.push({ path: '/welcome', query: {} });
    },
    f_logout: function () {
      this.$router.push({ 'path': '/logout', 'query': {} });
    },
    f_calculateMenuItemStyle: function (route_name) {
      let style = '';
      if (this.$route.name === route_name) {
        // style = 'background-color: rgb(12, 147, 65);';
        style = 'background-color: rgb(80, 87, 91);';
      }
      return style;
    },
    f_sideBarStyle: function () {
      let style = 'height: ' + (window.innerHeight - 50).toString() + 'px;';
      return style;
    },
    f_goToThisPage: function (route_path) {
      if (this.$route.path !== route_path) {
        this.$router.push({ 'path': route_path, 'query': {} });
      }
    },
    f_goToThisUrl: function (url) {
      window.open(url, '_blank');
    },
    ConfirmStoreModal: function () {
      let data = JSON.parse(JSON.stringify(this.StoreModal.data));
      data.confirm = true;
      this.$store.commit('modal', data);
      this.show_store_modal = false;
    },
    f_showProgressBarByInterval: function () {
      this.showProgresBarInterval = setInterval(() => {
        if (!this.d_progressData) {
          this.d_progressData = {};
        }
        if (this.d_progressData.percent === undefined) {
          this.d_progressData.percent = 5;
        } else {
          if (this.d_progressData.percent <= 50) {
            this.d_progressData.percent += 4;
          } else if (this.d_progressData.percent > 50 && this.d_progressData.percent <= 75) {
            this.d_progressData.percent += 3;
          } else if (this.d_progressData.percent > 75 && this.d_progressData.percent <= 85) {
            this.d_progressData.percent += 2;
          } else if (this.d_progressData.percent > 85 && this.d_progressData.percent < 99) {
            this.d_progressData.percent += 1;
          }
        }
        this.$forceUpdate();
      }, 500);
    },
    f_showUserName: function () {
      let result_name = '';
      let result_name_list = [];
      if (this.user && this.user.firstname) {
        let firstname = this.user.firstname;
        let lastname = this.user.lastname;
        let firstname_list = firstname.split(' ');
        for (let i in firstname_list) {
          if (parseInt(i) + 1 === firstname_list.length) {
            result_name_list.push(firstname_list[i]);
          } else {
            result_name_list.push(firstname_list[i][0]);
          }
        }
        let lastname_list = lastname.split(' ');
        for (let i in lastname_list) {
          if (parseInt(i) + 1 === lastname_list.length) {
            result_name_list.push(lastname_list[i]);
          } else {
            result_name_list.push(lastname_list[i][0]);
          }
        }
        result_name = result_name_list.join(' ');
      }
      return result_name;
    }
  },
  watch: {
    'show_store_modal': function () {
      if (!this.show_store_modal) {
        this.$store.commit('modal_show', false);
      }
    },
    'StoreLoading.status': function () {
      if (this.StoreLoading.status) {
        this.ShowLoadingModal = true;
        this.d_progressData = '';
        this.f_showProgressBarByInterval();
      } else {
        this.ShowLoadingModal = false;
        this.d_progressData = '';
        clearInterval(this.showProgresBarInterval);
      }
    },
    'StoreModal.show': function () {
      if (this.StoreModal.show) {
        this.show_store_modal = true;
      } else {
        this.show_store_modal = false;
        if (this.StoreModal.data.function_name) {
          if (this.StoreModal.data.function_name === 'f_addDefaultTextToHims' && this.StoreModal.data.confirm) {
            this.f_addDefaultTextToHims();
          }
        }
      }
    }
  }
}

</script>

<style type="text/css">


</style>

